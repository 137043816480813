import "core-js"

import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Consts from "../consts"
import CustomSlider from "../components/slider"

import { Color } from "../scss/colors"

import sbcImg from "../../static/logo-light.png"
import diagnosisIcon from "../../static/icons/diagnosis.png"
import programsIcon from "../../static/icons/programs.png"
import schoolingIcon from "../../static/icons/schooling.png"
import strategiesIcon from "../../static/icons/strategies.png"
import productIcon from "../../static/icons/product.png"
import elearningIcon from "../../static/icons/elearning.png"
// import pageIcon from "../../static/icons/page.png"
import tentIcon from "../../static/icons/tent.svg"

const LogoSBC = styled.div`
  width: 100%;
  height: 350px;
  background: url(${sbcImg}) no-repeat;
  background-size: contain;
  background-position: center;
  background-color: ${Color.Dark};
`

const SectionTitle = styled.h1`
  text-align: center;
  width: 100%;
  line-height: 1em;
`

const Offer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  max-width: 100vw;
  margin: 1.5em 0;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin: 0 1em;
`

const OfferTitle = styled.h2`
  text-align: center;
`

const OfferImage = styled.div`
  height: 50px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
`

const Description = styled.p`
  text-align: justify;
  margin-block-start: 2em;
`

const List = styled.ul``
const ListItem = styled.li``


const ContactDetails = styled.div`
    display: flex;
    justify-content: center;
`

const Mail = styled.a``
const Phone = styled.a``

const InternalLink = styled(Link)`
  text-decoration: none;
  padding-bottom: 2em;

  &:hover {
    transform: scale(1.1);
  }
`

const ExternalLink = styled.a`
  text-decoration: none;

  &:hover {
    transform: scale(1.1);
  }
`

const SectionContainer = styled.div`
  padding: 0 10vw;
  display: flex;
  flex-direction: column;
`

class IndexPage extends React.Component {

  constructor() {
    super()

    this.offerRef = React.createRef()
    this.aboutRef = React.createRef()
    this.referencesRef = React.createRef()
    this.contactRef = React.createRef()

    this.sectionRefs = {
      offer: this.offerRef,
      about: this.aboutRef,
      references: this.referencesRef,
      contact: this.contactRef,
    }
  }

  render() {
    return (
      <Layout location={this.props.location.pathname} sectionRefs={this.sectionRefs}>
        <SEO title="Szkolenia Badania Consulting" />

        <LogoSBC />

        <SectionContainer ref={this.offerRef}>
          <SectionTitle>Poznaj naszą ofertę</SectionTitle>

          <Offer>
            <Item>
              <InternalLink to={"/diagnozy"}>
                <OfferTitle style={{
                  color: Color.BurgundyDark,
                }}>
                  Diagnozy
                </OfferTitle>
                <OfferImage style={{ backgroundImage: `url(${diagnosisIcon})` }} />
              </InternalLink>
            </Item>

            <Item>
              <InternalLink to={"/strategie"}>
                <OfferTitle style={{
                  color: Color.Burgundy,
                }}>
                  Strategie
                </OfferTitle>
                <OfferImage style={{ backgroundImage: `url(${strategiesIcon})` }} />
              </InternalLink>
            </Item>

            <Item>
              <InternalLink to={"/programy"}>
                <OfferTitle style={{
                  color: Color.BurgundyLight,
                }}>
                  Programy
                </OfferTitle>
                <OfferImage style={{ backgroundImage: `url(${programsIcon})` }} />
              </InternalLink>
            </Item>

            <Item>
              <ExternalLink href="https://katalog.sbc.edu.pl/">
                <OfferTitle style={{
                  color: Color.Red,
                }}>
                  Gadżety i materiały
                </OfferTitle>
                <OfferImage style={{ backgroundImage: `url(${productIcon})` }} />
              </ExternalLink>
            </Item>

            <Item>
              <InternalLink to={"/szkolenia"}>
                <OfferTitle style={{
                  color: Color.Orange,
                }}>
                  Szkolenia online
                </OfferTitle>
                <OfferImage style={{ backgroundImage: `url(${elearningIcon})` }} />
              </InternalLink>
            </Item>

            <Item>
              <InternalLink to={"/szkolenia-wyjazdowe"}>
                <OfferTitle style={{
                  color: Color.Yellow,
                }}>
                  Szkolenia wyjazdowe
                </OfferTitle>
                <OfferImage style={{ backgroundImage: `url(${schoolingIcon})` }} />
              </InternalLink>
            </Item>

            {/*TBD - Kampanie*/}
            {/*<Item>*/}
            {/*  <InternalLink to={"/kampanie"}>*/}
            {/*    <OfferTitle style={{*/}
            {/*      color: Color.RedBerry,*/}
            {/*    }}>*/}
            {/*      Kampanie*/}
            {/*    </OfferTitle>*/}
            {/*    <OfferImage style={{ backgroundImage: `url(${pageIcon})` }} />*/}
            {/*  </InternalLink>*/}
            {/*</Item>*/}

            <Item>
              <InternalLink to={"/namiot-profilaktyczny"}>
                <OfferTitle style={{
                  color: Color.Dark
                }}>
                  Namiot profilaktyczny
                </OfferTitle>
                <OfferImage style={{ backgroundImage: `url(${tentIcon})` }} />
              </InternalLink>
            </Item>
          </Offer>
        </SectionContainer>

        <SectionContainer ref={this.aboutRef}>
          <SectionTitle>O nas</SectionTitle>

          <Description>
            Firma SBC (Szkolenia Badania Consulting) specjalizuje się we wszechstronnych szkoleniach skierowanych
            do wszystkich osób pracujących <nobr>z drugim</nobr> człowiekiem <nobr>w obszarze</nobr> profilaktyki i
            rozwiązywania problemów społecznych. Adresatami oferowanych szkoleń są między innymi pracownicy
            Miejskich <nobr>i Gminnych</nobr> Ośrodków
            Pomocy Społecznej, asystenci rodziny, członkowie Zespołów Interdyscyplinarnych, Gminnych Komisji
            Rozwiązywania
            Problemów Alkoholowych, pracownicy sądów, kuratorzy oraz mediatorzy.
          </Description>
          <Description>
            Oferta naszej firmy obejmuje również:
          </Description>
          <List>
            <ListItem>
              przeprowadzanie profesjonalnych <nobr>i rzetelnych</nobr> badań społecznych, które pozwalają na pełną
              diagnozę obecnych problemów
              występujących <nobr>w danej</nobr> społeczności lokalnej;
            </ListItem>
            <ListItem>
              realizację Strategii rozwiązywania problemów społecznych, która pozwala wskazać najważniejsze obszary
              problemowe,
              priorytetowe kierunki działań oraz wytyczne dotyczące ich wdrażania;
            </ListItem>
            <ListItem>
              tworzenie programów profilaktyki <nobr>i rozwiązywania</nobr> problemów alkoholowych, na który składa się
              szczegółowa
              analiza problemów <nobr>i zjawisk</nobr> społecznych, a także wskazanie celów oraz sposobów rozwiązywania
              problemów alkoholowych
              <nobr>oraz innych</nobr> związanych <nobr>z tą </nobr>sferą problemów;
            </ListItem>
            <ListItem>
              opracowywanie Gminnych programów przeciwdziałania przemocy <nobr>w rodzinie</nobr> oraz ochrony ofiar
              przemocy <nobr>w rodzinie</nobr>, które
              są dokumentami wyznaczającymi cele oraz sposoby rozwiązywania problemów przemocy <nobr>w rodzinie</nobr>
              <nobr>i związanych</nobr>
              <nobr>z tą</nobr>
              sferą
              problemów rodzinnych <nobr>i społecznych.</nobr>
            </ListItem>
          </List>
          <Description>
            Za jakość oferowanych przez nas usług odpowiada zespół, <nobr>w skład</nobr> którego wchodzą osoby
            specjalizujące się w
            pedagogice resocjalizacyjnej, penitencjarystyce, interwencji kryzysowej, psychoterapii, socjologii, terapii
            uzależnień,
            mediacjach rodzinnych, pracy socjalnej, a także kuratorzy, prokuratorzy, prawnicy, asystenci rodziny,
            przewodniczący jednostek
            pomocy społecznej (ZI, GKRPA, OPS) oraz specjaliści <nobr>w zakresie</nobr> przeciwdziałania
            przemocy <nobr>i alkoholizmowi.</nobr>
          </Description>
          <Description>
            Firma SBC organizuje szkolenia online, szkolenia wyjazdowe i szkolenia indywidualne w dowolnej formie na
            życzenie klienta.
          </Description>
        </SectionContainer>

        <SectionContainer ref={this.referencesRef}>
          <SectionTitle>Referencje</SectionTitle>
          <CustomSlider />
        </SectionContainer>

        <SectionContainer ref={this.contactRef}>
          <SectionTitle>Kontakt</SectionTitle>

          <ContactDetails>
            <List style={{
              display: `inline-block`,
              listStyleType: `none`,
              marginBlockStart: `0`,
              paddingInlineStart: `0`,
            }}>
              <ListItem>SZKOLENIA BADANIA CONSULTING</ListItem>
              <ListItem>ul. Tadeusza Szafrana 5/73,</ListItem>
              <ListItem>30-363 Kraków</ListItem>
              <ListItem>tel:
                <Phone
                  href={`tel:${Consts.DATA.PHONE.LANDLINE}`}
                >{Consts.DATA.PHONE.LANDLINE}</Phone>
              </ListItem>
              <ListItem>
                mob:
                <Phone
                  href={`tel:${Consts.DATA.PHONE.CELLPHONE}`}
                >{Consts.DATA.PHONE.CELLPHONE}</Phone>
              </ListItem>
              <ListItem>mail: <Mail href={`mailto:${Consts.DATA.MAIL}`}>{Consts.DATA.MAIL}</Mail></ListItem>
            </List>
          </ContactDetails>

        </SectionContainer>

      </Layout>
    )
  }
}

export default IndexPage
