import React from "react"
import Slider from "react-slick"
import styled from "styled-components"

import "./slider.scss"

import ref1 from "../../static/references/janikowo_gdansk.png"
import ref2 from "../../static/references/klodzko.png"
import ref3 from "../../static/references/bb.png"
import ref4 from "../../static/references/bystra.png"
import ref5 from "../../static/references/konstancin.png"
import ref6 from "../../static/references/rembertow.png"
import ref7 from "../../static/references/zakopane.png"
import ref8 from "../../static/references/drobin.png"
import ref9 from "../../static/references/olawa.png"
import ref10 from "../../static/references/torun.png"


const SliderWrapper = styled.div`
  max-width: 90%;
  margin: 0 auto;
`

class CustomSlider extends React.Component {

  render() {
    const references = [
      { key: '1', img: ref1 },
      { key: '2', img: ref2 },
      { key: '3', img: ref3 },
      { key: '4', img: ref4 },
      { key: '5', img: ref5 },
      { key: '6', img: ref6 },
      { key: '7', img: ref7 },
      { key: '8', img: ref8 },
      { key: '9', img: ref9 },
      { key: '10', img: ref10 },

      // // year 2023
      // { key: '13', img: ref13 },
      // { key: '14', img: ref14 },
      // { key: '15', img: ref15 },
      // { key: '16', img: ref16 },
      // { key: '17', img: ref17 },
      // { key: '18', img: ref18 },
      // { key: '19', img: ref19 },
      //
      // // year 2022+
      // { key: "4", img: ref4 },
      // { key: "5", img: ref5 },
      // { key: "6", img: ref6 },
      // { key: "7", img: ref7 },
      // { key: "8", img: ref8 },
      // { key: "10", img: ref10 },
      // { key: "11", img: ref11 },
      // { key: "12", img: ref12 },
      // // year 2019
      // { key: "1", img: ref1 },
      // { key: "2", img: ref2 },
      // { key: "3", img: ref3 },
    ]

    const settings = {
      dots: true,
      // infinite: true,
      // slidesToShow: 1,
      // slidesToScroll: 1,
      autoplay: true,
      // speed: 2000,
      autoplaySpeed: 5000,
      // cssEase: "linear"
    }
    return (
      <SliderWrapper>
        <Slider {...settings}>
          {
            references.map(({ key, img }) =>
              <div
                key={key}
              >
                <div className={"icon-wrapper"}
                     style={{ backgroundImage: `url(${img})` }}>
                </div>
              </div>,
            )
          }
        </Slider>
      </SliderWrapper>
    )
  }
}

export default CustomSlider
